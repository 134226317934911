.users_stats .infographic .group {
  display: block;
  margin: 0.3em 0;
  min-height: 8.7em;
  padding: 1em 0;
  position: relative;
}
.users_stats .infographic .group .climb-items,
.users_stats .infographic .group .pie {
  display: none;
  position: absolute;
  height: 240px;
  right: 1em;
  top: 1em;
  width: 480px;
}
.users_stats .infographic .group .climb-items .legend table,
.users_stats .infographic .group .pie .legend table {
  font-size: 12px !important;
}
.users_stats .infographic .group .climb-items .legend table td,
.users_stats .infographic .group .pie .legend table td {
  padding-bottom: 1px;
}
.users_stats .infographic .group .moreinfo {
  left: 4em;
  position: absolute;
  text-align: center;
  top: 4em;
  width: 150px;
}
.users_stats .infographic .group .moreinfo span {
  display: block;
  line-height: 1em;
  font-size: 2em;
}
.users_stats .infographic .group .moreinfo label {
  display: block;
  font-size: 0.4em;
  line-height: 0.2em;
}
.users_stats .infographic .group .climb-items {
  width: 550px;
}
.users_stats .infographic .group .climb-items .climb-item {
  float: left;
  font-size: 12px;
  margin: 5px 10px;
  width: 255px;
}
.users_stats .infographic .group .climb-items .climb-item .trophy {
  float: left;
  margin-right: 6px;
}
.users_stats .infographic .group .climb-items .climb-item .info {
  float: left;
  width: 225px;
}
.users_stats .infographic .group .climb-items .climb-item .info label {
  font-size: inherit;
  font-weight: bold;
}
.users_stats .infographic .days,
.users_stats .infographic .sends {
  display: block;
  float: left;
  margin: -0.6em 2.5em 1em 0;
  position: relative;
  zoom: 1;
}
.users_stats .infographic .days span,
.users_stats .infographic .sends span {
  display: block;
  font-size: 5em;
  letter-spacing: -0.05em;
  position: relative;
  text-align: center;
}
.users_stats .infographic .days label,
.users_stats .infographic .sends label {
  display: block;
  font-size: 0.6em;
  margin-top: -1em;
  position: relative;
  text-align: center;
}
.users_stats .infographic .days {
  margin-left: -1em;
}
.users_stats .infographic .minor {
  float: left;
  margin-left: -1em;
}
.users_stats .infographic .minor div {
  float: left;
}
.users_stats .infographic .minor div span {
  display: block;
  font-size: 2em;
  text-align: center;
}
.users_stats .infographic .minor div label {
  font-size: 0.35em;
  display: block;
  margin-top: -1em;
  text-align: center;
}
.users_stats .infographic .minor div.fas,
.users_stats .infographic .minor div.locations {
  cursor: pointer;
  position: relative;
}
.users_stats .infographic .minor div.fas {
  margin-bottom: 0.3em;
  margin-right: 1em;
}
.users_stats .infographic .minor div.fas span {
  padding: 0 0.1em;
  text-align: center;
}
.users_stats .infographic .minor div.areas {
  margin-right: 1em;
}
.users_stats .infographic .minor div.countries {
  margin-right: 0.5em;
}
.users_stats .infographic .minor div.clear {
  float: none;
}
.users_stats .infographic .grade {
  float: left;
  line-height: 0.6em;
  padding: 0.2em 1em 0 0;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.users_stats .infographic .grade span {
  font-size: 2.5em;
}
.users_stats .infographic .grade label {
  font-size: 0.4em;
  /*letter-spacing: 0.1em;*/
}
.users_stats .infographic .grade .close_btn {
  top: -0.6em;
}
.users_stats .infographic .grade .chart {
  font-size: 16px;
  height: 50px;
  margin: -5px auto 0 auto;
  width: 100px;
}
.users_stats .infographic .close_btn {
  background: url(/img/close-button-16x16.gif);
  display: none;
  height: 16px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 16px;
}
