
.users_stats {

	.infographic {
		
		.group {
			display: block;
			margin: 0.3em 0;
			min-height: 8.7em;
			padding: 1em 0;
			position: relative;
			
			.climb-items,
			.pie {
				display: none;
				position: absolute;
				height: 240px;
				right: 1em;
				top: 1em;
				width: 480px;
				
				.legend {
					table {
						font-size: 12px !important; 
						
						td {
							padding-bottom: 1px; 
						}
					}
				}
			}
		
			.moreinfo {
				left: 4em;
				position: absolute;
				text-align: center;
				top: 4em;
				width: 150px;
				
				span {
					display: block;
					line-height: 1em;
					font-size: 2em;
				}
				label {
					display:block;
					font-size: 0.4em;
					line-height: 0.2em;
				}
			}
			
			.climb-items {
				width: 550px;
				
				.climb-item {
					float: left;
					font-size: 12px;
					margin: 5px 10px;
					width: 255px;

					.trophy {
						float: left;
						margin-right: 6px;
					}				
					.info {
						float: left;
						width: 225px;


						label {
							font-size: inherit;
							font-weight: bold;
						}
						
					}	
				}
			}	
		}
		
		
		.days,
		.sends {
			display: block;
			float: left;
			margin: -0.6em 2.5em 1em 0;
			position: relative;
			zoom: 1;
		
			span {
				display: block;
				font-size: 5em; 
				letter-spacing: -0.05em;
				position: relative;
				text-align: center;
			}
			
			label {
				display: block;
				font-size: 0.6em;
				margin-top: -1em;
				position: relative;
				text-align: center;
			}
		}
		.days { margin-left: -1em; }
		
		.minor {
			float: left;
			margin-left: -1em;
		
			div {
				float: left;
			
				span {
					display: block;
					font-size: 2em; 
					text-align: center;
				}
				
				label {
					font-size: 0.35em;
					display: block;
					margin-top: -1em;
					text-align: center;
					
				}
				&.fas,&.locations {
					cursor: pointer;
					position: relative;
				}
				&.fas {
					margin-bottom: 0.3em;
					margin-right: 1em;
					
					span {
						padding: 0 0.1em;
						text-align: center;
						
					}
				}				
				&.areas {
					margin-right: 1em;
				}
				&.countries {
					margin-right: 0.5em;
				}
				
				&.clear { float: none; }
			}
		}
		
		
		.grade { 
			float: left;
			line-height: 0.6em;
			padding: 0.2em 1em 0 0;
			position: relative;
			text-align: center;
			cursor: pointer;
			
			span {
				font-size: 2.5em; 
			}
			
			label {
				font-size: 0.4em;
				/*letter-spacing: 0.1em;*/
			}
			.close_btn {
				top: -0.6em;
			}
			.chart {
				font-size: 16px;
				height: 50px;
				margin: -5px auto 0 auto;
				width: 100px;
			}
		}
		
		.close_btn {
			background: url(/img/close-button-16x16.gif);
			display: none;
			height: 16px; 
			position: absolute;
			right: 0px;
			top: 0px;
			width: 16px;
		}
	}
	

}
